@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] { display: none !important; }

.privacy-and-cookies-policy,
.terms-of-service {
    li {
        margin: 10px 0;
    }
    > li {
        font-weight: bold;

        ol,
        ul {
            font-weight: normal;
        }
    }
}
